import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import updateProductStock from './updateProductStock';

const addOrder = async (transaction_id, ordered_cart, cartItems) => {
  try {
    // Add order with only required fields
    const ordersCollection = collection(db, 'order_details');
    const newOrder = {
      transaction_id,
      ordered_cart,
      apiKey:'AIzaSyBwvHcJTXrqeknFeSNJMHsqAM27jvDfaWc'
    };
    await addDoc(ordersCollection, newOrder);
    
    // Update stock for each product
    const stockUpdatePromises = cartItems.map(item => 
      updateProductStock(item.product_id, item.quantity)
    );
    await Promise.all(stockUpdatePromises);
    
  } catch (error) {
    console.error("Error processing order: ", error);
    throw error;
  }
};

export default addOrder;