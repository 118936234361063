import React from 'react';
import { Dialog, DialogContent, Typography, Box, Button } from '@mui/material';
import { CheckCircle, XCircle, AlertCircle } from 'lucide-react';

// Alert types and their corresponding colors and icons
const ALERT_STYLES = {
  success: {
    icon: CheckCircle,
    color: '#4CAF50',
    bgColor: '#E8F5E9'
  },
  error: {
    icon: XCircle,
    color: '#F44336',
    bgColor: '#FFEBEE'
  },
  warning: {
    icon: AlertCircle,
    color: '#FF9800',
    bgColor: '#FFF3E0'
  }
};

const StyledAlert = ({ 
  open, 
  onClose, 
  message, 
  type = 'success', 
  title,
  buttonText = 'OK'
}) => {
  const alertStyle = ALERT_STYLES[type];
  const Icon = alertStyle.icon;

  const handleClose = () => {
    onClose();
    // Redirect to home page only if alert type is success
    if (type === 'success') {
      window.location.href = '/';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          p: 2,
          backgroundColor: alertStyle.bgColor
        }
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            py: 3
          }}
        >
          <Icon 
            size={64} 
            color={alertStyle.color}
            style={{ marginBottom: '1rem' }}
          />
          
          {title && (
            <Typography
              variant="h5"
              sx={{
                color: alertStyle.color,
                fontWeight: 'bold',
                mb: 2
              }}
            >
              {title}
            </Typography>
          )}

          <Typography
            variant="body1"
            sx={{
              color: '#333',
              mb: 4,
              fontSize: '1.1rem',
              maxWidth: '80%',
              lineHeight: 1.6
            }}
          >
            {message}
          </Typography>

          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: alertStyle.color,
              color: 'white',
              px: 4,
              py: 1,
              borderRadius: 2,
              '&:hover': {
                backgroundColor: alertStyle.color,
                opacity: 0.9
              }
            }}
          >
            {buttonText}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StyledAlert;