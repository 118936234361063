import {
  Container,
  Typography,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  useMediaQuery,
  Pagination
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import fetchProducts from '../fetchProducts'
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

const Home = () => {
  const [products, setProducts] = useState([])
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const itemsPerPage = 16

  const getProducts = async () => {
    const cachedProducts = localStorage.getItem('products')
    const cachedTimestamp = localStorage.getItem('productsTimestamp')
    const currentTime = new Date().getTime()

    if (cachedProducts && cachedTimestamp && currentTime - cachedTimestamp < 3600) { // 1 hour = 3600000 ms
      setProducts(JSON.parse(cachedProducts))
    } else {
      const productsList = await fetchProducts()
      setProducts(productsList)
      localStorage.setItem('products', JSON.stringify(productsList))
      localStorage.setItem('productsTimestamp', currentTime)
    }
  }

  useEffect(() => {
    getProducts()
  }, [])

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
  }

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const paginatedProducts = filteredProducts.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  )

  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Container maxWidth style={{ padding: 5, marginTop: '3vh' }}>
      <TextField
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder='Search Products'
        variant='outlined'
        id='top'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon style={{ color: '#d5a439', marginLeft: 10 }} />
            </InputAdornment>
          )
        }}
        sx={{
          width: isLargeScreen ? '40%' : '100%',
          color: '#d5a439',
          height: '30px', // Adjust the height for a thinner appearance
          '& .MuiOutlinedInput-root': {
            padding: '0px', // Remove padding for thinner appearance
            '& .MuiInputAdornment-root': {
              margin: '0px' // Adjust margin for thinner appearance
            },
            '& input': {
              padding: '5px 10px' // Adjust input padding
            }
          }
        }}
      />

      <Box my={3} sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
          {paginatedProducts.map((product, index) => (
            <Grid key={index} size={{ xs: 2, lg: 3 }}>
              <Card
                elevation={0}
                style={{
                  width: 'fixed',
                  minHeight: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <NavLink
                  to={`/info/${product.product_id}`}
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    src={product.main_image}
                    alt='Course'
                    width='100%'
                    style={{ marginTop: 3 }}
                  />
                  <CardContent>
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      textAlign='center'
                      color='#d5a439'
                      style={{
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px'
                      }}
                    >
                      {product.name}
                    </Typography>

                    {product.discount_price ? (
                      <Box textAlign='center'>
                        <Typography
                          variant='h6'
                          fontWeight={400}
                          color='#d5a439'
                          component='div'
                        >
                          ₹{product.discount_price}
                        </Typography>
                        <Box display='flex' sx={{justifyContent:'center'}}>
                          {/* <Typography
                            variant='h8'
                            color='red'
                            sx={{ marginRight: 1 }}
                            component='div'
                          >
                            -
                            {(
                              ((product.price - product.discount_price) /
                                product.price) *
                              100
                            ).toFixed(2)}
                            % Off
                          </Typography> */}

                          <Typography variant='h8' color='grey' component='div'>
                            MRP:
                          </Typography>
                          <Typography
                            variant='h8'
                            component='div'
                            color='grey'
                            style={{
                              marginLeft: 10,
                              textDecoration: 'line-through'
                            }}
                          >
                            ₹{product.price}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        variant='h6'
                        textAlign='center'
                        fontWeight={400}
                        color='#d5a439'
                      >
                        ₹ {product.price}
                      </Typography>
                    )}
                  </CardContent>
                </NavLink>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box display='flex' justifyContent='center' my={3}>
        <Pagination
          count={Math.ceil(filteredProducts.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color='primary'
        />
      </Box>
    </Container>
  )
}

export default Home
