import * as React from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Badge
} from '@mui/material'
import { styled } from '@mui/material/styles'
import logo from '../logo.jpeg'
import { Link, useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useCart } from './CartContext'

function Nav() {
  const { cart } = useCart()
  const location = useLocation()

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElSubNav, setAnchorElSubNav] = React.useState(null)
  const [anchorElSarees, setAnchorElSarees] = React.useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
    setAnchorElSubNav(null)
    setAnchorElSarees(null)
  }

  const handleOpenSubNavMenu = event => {
    setAnchorElSubNav(event.currentTarget)
  }

  const handleOpenSareesMenu = event => {
    setAnchorElSarees(event.currentTarget)
  }

  const handleCloseSareesMenu = () => {
    setAnchorElSarees(null)
    handleCloseNavMenu()
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -5,
      top: 5,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px'
    }
  }))

  const getButtonStyle = path => ({
    fontWeight:400,
    
    color: location.pathname === path ? 'white' : '#ad0002',
    backgroundColor: location.pathname === path ? '#ad0002' : 'transparent',
    
  })

  return (
    <AppBar position='sticky' elevation={0} sx={{ backgroundColor: 'white' }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            component='a'
            href='/'
            sx={{
              display: { xs: 'none', md: 'flex' }
            }}
          >
            <img
              style={{
                width: '70px',
                height: '70px',
                marginRight: 25,
              }}
              src={logo}
              alt='Chirapathi'
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label=''
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon style={{ color: 'grey' }} />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuItem>
                <Typography
                  component='a'
                  href='/'
                  sx={{ textDecoration: 'none', color: '#ad0002' }}
                >
                  Home</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleOpenSubNavMenu}
                aria-controls='menu-subappbar'
                sx={{ color: '#ad0002' }}
              >
                Categories <ChevronRightIcon />
              </MenuItem>
              <Menu
                id='menu-subappbar'
                anchorEl={anchorElSubNav}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElSubNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <MenuItem component={Link} to='/CoOrdSuits'>
                  Co-ord Suits
                </MenuItem>
                <MenuItem onClick={handleOpenSareesMenu}>
                  Sarees <ChevronRightIcon />
                </MenuItem>
                <Menu
                  id='menu-sarees'
                  anchorEl={anchorElSarees}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  open={Boolean(anchorElSarees)}
                  onClose={handleCloseSareesMenu}
                  sx={{ display: { xs: 'block', md: 'none' } }}
                >
                  <MenuItem
                    component={Link}
                    to='/Sarees/PureSilk'
                    onClick={handleCloseSareesMenu}
                  >
                    Pure Silk
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/Sarees/Curated'
                    onClick={handleCloseSareesMenu}
                  >
                    Curated
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/Sarees/PartyWear'
                    onClick={handleCloseSareesMenu}
                  >
                    Party Wear
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/Sarees/Cotton'
                    onClick={handleCloseSareesMenu}
                  >
                    Cotton
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/Sarees/SilkBlend'
                    onClick={handleCloseSareesMenu}
                  >
                    Silk Blend
                  </MenuItem>
                </Menu>
                <MenuItem component={Link} to='/Kurtis'>
                  Kurtis
                </MenuItem>
                <MenuItem component={Link} to='/OccasionalWear'>
                  Occasional Wear
                </MenuItem>
                <MenuItem component={Link} to='/ReadyToDispatch'>
                  Ready to Dispatch
                </MenuItem>
                <MenuItem component={Link} to='/UnstitchedMaterials'>
                  Unstitched Materials
                </MenuItem>
                <MenuItem component={Link} to='/Dupattas'>
                  Dupatta’s
                </MenuItem>
                <MenuItem component={Link} to='/Accessories'>
                  Accessories
                </MenuItem>
              </Menu>
              <MenuItem
                sx={{ textDecoration: 'none', color: '#ad0002' }}
                component={Link}
                to='/OfflineStore'
              >
                Offline Store
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant='h5'
            noWrap
            component='a'
            href='/'
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1
            }}
          >
            <img
              style={{
                width: '90px',
                height: '90px',
                marginRight:10
              }}
              src={logo}
              alt='Chirapathi'
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button component={Link} to='/' sx={getButtonStyle('/')}>
              Home
            </Button>
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={getButtonStyle('/n')}            >
              Categories  <ChevronRightIcon />
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem component={Link} to='/CoOrdSuits'>
                Co-ord Suits
              </MenuItem>
              <MenuItem onClick={handleOpenSareesMenu}>
                Sarees <ChevronRightIcon />
              </MenuItem>
              <Menu
                id='menu-sarees'
                anchorEl={anchorElSarees}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElSarees)}
                onClose={handleCloseSareesMenu}
              >
                <MenuItem
                  component={Link}
                  to='/Sarees/PureSilk'
                  onClick={handleCloseSareesMenu}
                >
                  Pure Silk
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/Sarees/Curated'
                  onClick={handleCloseSareesMenu}
                >
                  Curated
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/Sarees/PartyWear'
                  onClick={handleCloseSareesMenu}
                >
                  Party Wear
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/Sarees/Cotton'
                  onClick={handleCloseSareesMenu}
                >
                  Cotton
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/Sarees/SilkBlend'
                  onClick={handleCloseSareesMenu}
                >
                  Silk Blend
                </MenuItem>
              </Menu>
              <MenuItem component={Link} to='/Kurtis'>
                Kurtis
              </MenuItem>
              <MenuItem component={Link} to='/OccasionalWear'>
                Occasional Wear
              </MenuItem>
              <MenuItem component={Link} to='/ReadyToDispatch'>
                Ready to Dispatch
              </MenuItem>
              <MenuItem component={Link} to='/UnstitchedMaterials'>
                Unstitched Materials
              </MenuItem>
              <MenuItem component={Link} to='/Dupattas'>
                Dupatta’s
              </MenuItem>
              <MenuItem component={Link} to='/Accessories'>
                Accessories
              </MenuItem>
            </Menu>
            <Button
              component={Link}
              to='/OfflineStore'
              sx={getButtonStyle('/OfflineStore')}
            >
              Offline Store
            </Button>
          </Box>
          <IconButton component={Link} to='/Cart'>
            <StyledBadge badgeContent={cart.length} color='secondary'>
              <ShoppingCartIcon style={{ color: 'grey' }} />
            </StyledBadge>
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Nav
