import { Routes, Route } from 'react-router-dom'
import { Container, Box } from '@mui/material'
import { useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
/**********************************/
import './App.css'
import Home from './components/home'
import ProductInfo from './components/productInfo'
import Nav from './components/nav'
import Header from './components/header'
import Footer from './components/footer'
import Cart from './components/cart'
import Checkout from './components/checkout'
import CoOrdSuits from './components/category.coOrdSuits'
import OccasionalWear from './components/category.occasionalWear'
import Kurtis from './components/category.kurtis'
import ReadyToDispatch from './components/category.readyToDispatch'
import UnstitchedMaterials from './components/category.unstitchedMaterials'
import Dupattas from './components/category.dupattas'
import PartyWear from './components/category.partyWear'
import Curated from './components/category.curated'
import PureSilk from './components/category.pureSilk'
import SilkBlend from './components/category.silkBlend'
import Cotton from './components/category.cotton'
import DispatchDelivery from './components/dispatchDelivery'
import AboutUs from './components/aboutUs'
import ReturnRefundExchange from './components/returnRefundExchange'
import OfflineStore from './components/offlineStore'
import Accessories from './components/category.accessories'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
const App = () => {
  disableReactDevTools()
  const theme = createTheme({
    typography: {
      fontFamily: 'Rally Symbols 2D Picto, Arial'
    },
    palette: {
      primary: {
        main: '#d5a439' // Change this to your desired primary color
      }
    }
  })
  const [cart, setCart] = useState([])

  const addToCart = product => {
    setCart([...cart, product])
  }
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Nav />
      <Container maxWidth disableGutters>
        <Box>
          <Routes>
            {/* Home */}
            <Route exact path='/' element={<Home />} />
            <Route exact path='/CoOrdSuits' element={<CoOrdSuits />} />
            <Route exact path='/Kurtis' element={<Kurtis />} />
            <Route exact path='/Accessories' element={<Accessories />} />

            <Route exact path='/OccasionalWear' element={<OccasionalWear />} />
            <Route
              exact
              path='/ReadyToDispatch'
              element={<ReadyToDispatch />}
            />
            <Route
              exact
              path='/UnstitchedMaterials'
              element={<UnstitchedMaterials />}
            />
            <Route exact path='/Dupattas' element={<Dupattas />} />
            <Route exact path='/Sarees/PureSilk' element={<PureSilk />} />
            <Route exact path='/Sarees/Curated' element={<Curated />} />
            <Route exact path='/Sarees/PartyWear' element={<PartyWear />} />
            <Route exact path='/Sarees/SilkBlend' element={<SilkBlend />} />
            <Route exact path='/Sarees/Cotton' element={<Cotton />} />

            {/* Product Info*/}
            <Route
              exact
              path='/info/:product_id'
              element={<ProductInfo addToCart={addToCart} />}
            />
            {/* Cart */}
            <Route exact path='/Cart' element={<Cart cart={cart} />} />
            {/* Buy Now */}
            <Route exact path='/Checkout' element={<Checkout />} />
            {/* Orders */}
            {/* Categories */}

            
            {/*Contact Us*/}
            <Route exact path='/AboutUs' element={<AboutUs />} />

            {/*Cancellation and Refund*/}
            <Route exact path='/ReturnRefundExchange' element={<ReturnRefundExchange />} />

            {/*Terms & Conditions*/}
            {/*Shipping and Delivery*/}
            <Route exact path='/DispatchDelivery' element={<DispatchDelivery />} />
            <Route exact path='/OfflineStore' element={<OfflineStore />} />

          </Routes>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  )
}

export default App
