// updateProductStock.js
import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';

const updateProductStock = async (product_id, quantity) => {
  try {
    const productsCollection = collection(db, 'products');
    const q = query(productsCollection, where("product_id", "==", product_id));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const productDoc = querySnapshot.docs[0];
      const currentStock = productDoc.data().stock;
      const newStock = Math.max(currentStock - quantity, 0);
      
      await updateDoc(doc(db, 'products', productDoc.id), {
        stock: newStock,
        apiKey: 'AIzaSyBwvHcJTXrqeknFeSNJMHsqAM27jvDfaWc'
      });
    }
  } catch (error) {
    console.error("Error updating stock: ", error);
    throw error;
  }
};

export default updateProductStock;