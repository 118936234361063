import { useNavigate, useParams } from 'react-router-dom'
import fetchProducts from '../fetchProducts'
import { useState, useEffect, useCallback } from 'react'
import { useCart } from './CartContext'
import { memo, Suspense } from 'react'
import {
  Card,
  CardContent,
  Alert,
  Typography,
  FormControl,
  Button,
  Box,
  Container,
  Skeleton,
  ButtonGroup,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import StraightenIcon from '@mui/icons-material/Straighten'
import { debounce } from 'lodash'
import React from 'react'

const ImageCarousel = React.lazy(() => import('./ImageCarousel'))

const SizeChartDialog = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const sizeData = [
    { size: 'XXS', chest: '30', waist: '24', hip: '34', shoulder: '13.5', armhole: '14' },
    { size: 'XS', chest: '32', waist: '26', hip: '36', shoulder: '14', armhole: '15' },
    { size: 'S', chest: '34', waist: '28', hip: '38', shoulder: '14.5', armhole: '16' },
    { size: 'M', chest: '36', waist: '30', hip: '40', shoulder: '15', armhole: '17' },
    { size: 'L', chest: '38', waist: '32', hip: '42', shoulder: '15.5', armhole: '18' },
    { size: 'XL', chest: '40', waist: '34', hip: '44', shoulder: '16', armhole: '19' },
    { size: '2XL', chest: '42', waist: '36', hip: '46', shoulder: '16.5', armhole: '20' },
    { size: '3XL', chest: '44', waist: '38', hip: '48', shoulder: '17', armhole: '21' },
    { size: '4XL', chest: '46', waist: '40', hip: '50', shoulder: '17.5', armhole: '22' },
    { size: '5XL', chest: '48', waist: '42', hip: '52', shoulder: '18', armhole: '23' },
  ];

  const additionalInfo = [
    { text: 'Note - our kurta and bottom length is completely customisable according to your height, so kindly mention your height in cm or in feet' },
    { text: 'Also kurta length max can be 43, more than 43 inches in length will be additional 120/-, if your 5.4 feet and above calculate it accordingly' },
    { text: 'If you already have your own measurements and sure about it can provide us, will be 120/- additional for customisation' }
  ];

  return (
    <>
      <Button
        startIcon={<StraightenIcon />}
        onClick={handleOpen}
        variant="outlined"
        size="small"
        sx={{ mb: 2 }}
      >
        Size Chart
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Size Chart</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Size</TableCell>
                  <TableCell>Chest</TableCell>
                  <TableCell>Waist</TableCell>
                  <TableCell>Hip</TableCell>
                  <TableCell>Shoulder</TableCell>
                  <TableCell>Armhole</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sizeData.map((row) => (
                  <TableRow key={row.size}>
                    <TableCell>{row.size}</TableCell>
                    <TableCell>{row.chest}</TableCell>
                    <TableCell>{row.waist}</TableCell>
                    <TableCell>{row.hip}</TableCell>
                    <TableCell>{row.shoulder}</TableCell>
                    <TableCell>{row.armhole}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 2 }}>
            {additionalInfo.map((info, index) => (
              <Typography key={index} variant="body2" sx={{ mb: 1 }}>
                {info.text}
              </Typography>
            ))}
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                fontSize: '1rem',
                fontStyle: 'italic',
                color: 'text.secondary',
                borderTop: '1px solid #eee',
                pt: 2
              }}
            >
              For any customizations and special requests <strong>Contact +91 7358083536</strong>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ProductInfo = () => {
  const { product_id } = useParams()
  const [productInfo, setProductInfo] = useState(null)
  const [imageArray, setImageArray] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [selectedSize, setSelectedSize] = useState('')
  const [currentPrice, setCurrentPrice] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [stockError, setStockError] = useState('')
  const { cart, addToCart } = useCart()
  const navigate = useNavigate()
  const id = product_id
  // 1. Add new state for selected sleeve
  const [selectedSleeve, setSelectedSleeve] = useState('')

  // 2. Add sleeve data constant at the top of the component
  const sleeveOptions = [
    { type: 'Sleeveless', price: 0 },
    { type: 'Elbow', price: 0 },
    { type: '3/4th', price: 0 },
    { type: 'Short Sleeve', price: 0 },
    { type: 'Slight Puff', price: 50 },
    { type: 'Full Sleeve', price: 100 },
    { type: 'Gathered Full Sleeve', price: 150 }
  ]
  const calculatePriceForSize = (basePrice, size, sleeve) => {
    const additionalPrices = {
      '2XL': 150,
      '3XL': 200,
      '4XL': 200,
      '5XL': 250
    }
    const sleevePrice = sleeveOptions.find(opt => opt.type === sleeve)?.price || 0
    return basePrice + (additionalPrices[size] || 0) + sleevePrice
  }
  // 4. Add sleeve change handler
  const handleSleeveChange = (event) => {
    const sleeve = event.target.value
    setSelectedSleeve(sleeve)
    updatePrice(selectedSize, sleeve)
  }
  const updatePrice = (size, sleeve) => {
    if (!productInfo) return
    const basePrice = productInfo.discount_price || productInfo.price
    const newPrice = calculatePriceForSize(basePrice, size, sleeve)
    setCurrentPrice(newPrice)
  }

  const getProducts = useCallback(async () => {
    const cachedProducts = localStorage.getItem('products')
    const cachedTime = localStorage.getItem('cacheTime')
    const now = Date.now()
    let productsList

    if (cachedProducts && cachedTime && now - cachedTime < 3600) {
      productsList = JSON.parse(cachedProducts)
    } else {
      productsList = await fetchProducts()
      localStorage.setItem('products', JSON.stringify(productsList))
      localStorage.setItem('cacheTime', now)
    }

    const product = productsList.find(item => item.product_id == id)
    if (product) {
      setProductInfo(product)
      setImageArray(product.images)
      setCurrentPrice(product.discount_price || product.price)
    } else {
      console.warn(`Product with id ${id} not found`)
    }
  }, [id])

  const debouncedGetProducts = useCallback(debounce(getProducts, 300), [getProducts])

  useEffect(() => {
    debouncedGetProducts()
    return () => debouncedGetProducts.cancel()
  }, [debouncedGetProducts])

  const handleSizeChange = (event) => {
    const size = event.target.value
    setSelectedSize(size)
    updatePrice(size)
  }

  const handleIncreaseQuantity = () => {
    const existingCartItem = cart.find(
      item => item.product_id === productInfo.product_id && item.selectedSize === selectedSize
    )
    const cartQuantity = existingCartItem ? existingCartItem.quantity : 0
    const totalQuantity = cartQuantity + quantity

    if (totalQuantity >= productInfo.stock) {
      setStockError(`Cannot add more items. Only ${productInfo.stock - cartQuantity} items available.`)
      return
    }
    setStockError('')
    setQuantity(quantity + 1)
  }

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
      setStockError('')
    }
  }

  const handleAddToCart = () => {
    if (productInfo.size && !selectedSize) {
      setErrorMessage('Please select a size.')
      return
    }
    if (productInfo.sleeves && !selectedSleeve) {
      setErrorMessage('Please select a sleeve type.')
      return
    }
  
    const existingCartItem = cart.find(
      item => item.product_id === productInfo.product_id && 
      item.selectedSize === selectedSize &&
      item.selectedSleeve === selectedSleeve
    )
    const cartQuantity = existingCartItem ? existingCartItem.quantity : 0
  
    if (cartQuantity + quantity > productInfo.stock) {
      setStockError(`Cannot add more items. Only ${productInfo.stock - cartQuantity} items available.`)
      return
    }
  
    setErrorMessage('')
    setStockError('')
    const modifiedProduct = {
      ...productInfo,
      price: productInfo.price,
      discount_price: productInfo.discount_price,
      final_price: currentPrice
    }
    addToCart(modifiedProduct, selectedSize, selectedSleeve, quantity)
  }
  

  const handleBuyNow = () => {
    if (productInfo.size && !selectedSize) {
      setErrorMessage('Please select a size.')
      return
    }
    if (productInfo.sleeves && !selectedSleeve) {
      setErrorMessage('Please select a sleeve type.')
      return
    }
  
    const existingCartItem = cart.find(
      item => item.product_id === productInfo.product_id && 
      item.selectedSize === selectedSize &&
      item.selectedSleeve === selectedSleeve
    )
    const cartQuantity = existingCartItem ? existingCartItem.quantity : 0
  
    if (cartQuantity + quantity > productInfo.stock) {
      setStockError(`Cannot add more items. Only ${productInfo.stock - cartQuantity} items available.`)
      return
    }
  
    setErrorMessage('')
    setStockError('')
    if (cart.length === 0) {
      const modifiedProduct = {
        ...productInfo,
        price: productInfo.price,
        discount_price: productInfo.discount_price,
        final_price: currentPrice
      }
      addToCart(modifiedProduct, selectedSize, selectedSleeve, quantity)
    }
    navigate('/Checkout')
  }
  if (!productInfo) {
    return (
      <Container maxWidth='md' disableGutters sx={{ height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: '100%' }}>
          <Box flex={1}>
            <Skeleton variant='rectangular' width='100%' height={300} />
          </Box>
          <Box flex={1}>
            <Card>
              <CardContent>
                <Skeleton variant='text' width='100%' />
                <Skeleton variant='text' width='60%' />
                <Skeleton variant='text' width='80%' />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    )
  }

  return (
    <Container maxWidth='md' disableGutters sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: '100%' }}>
        <Box flex={1}>
          <Suspense fallback={<Skeleton variant='rectangular' width='100%' height={300} />}>
            <ImageCarousel images={imageArray} />
          </Suspense>
        </Box>
        <Box flex={1}>
          <Card>
            <CardContent>
              <Typography variant='h5' component='div' style={{ textTransform: 'uppercase' }}>
                {productInfo.name}
              </Typography>
              <Typography color='text.secondary'>
                {productInfo.description.split('|').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}<br />
                  </React.Fragment>
                ))}
              </Typography>
              {productInfo.description1 && (
                <Typography color='text.secondary'>
                  {productInfo.description1.split('|').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}<br />
                    </React.Fragment>
                  ))}
                </Typography>
              )}

              <Typography variant='h6' component='div'>
                ₹{currentPrice}
              </Typography>
              {productInfo.discount_price && selectedSize !== '2XL' &&
                selectedSize !== '3XL' && selectedSize !== '4XL' &&
                selectedSize !== '5XL' && (
                  <Box display='flex'>
                    <Typography variant='h8' color='grey' component='div'>
                      MRP:
                    </Typography>
                    <Typography
                      variant='h8'
                      component='div'
                      color='grey'
                      style={{
                        marginLeft: 10,
                        textDecoration: 'line-through'
                      }}
                    >
                      ₹{productInfo.price}
                    </Typography>
                  </Box>
                )}

              {productInfo.stock > 0 ? (
                <>
                  <Box sx={{ marginTop: '2vh', marginBottom: '2vh' }}>
                    <Typography>
                      Quantity: {quantity}
                      {/* <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                        (Available: {productInfo.stock})
                      </Typography> */}
                    </Typography>
                    <ButtonGroup>
                      <Button
                        onClick={handleDecreaseQuantity}
                        disabled={quantity <= 1}
                      >
                        <RemoveIcon fontSize='small' />
                      </Button>
                      <Button
                        onClick={handleIncreaseQuantity}
                        disabled={quantity >= productInfo.stock}
                      >
                        <AddIcon fontSize='small' />
                      </Button>
                    </ButtonGroup>
                    {stockError && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ mt: 1 }}
                      >
                        {stockError}
                      </Typography>
                    )}
                  </Box>

                  {productInfo.size && (
                    <>
                      <SizeChartDialog />

                      <FormControl fullWidth margin='normal'>
                        <InputLabel id="size-select-label">Size</InputLabel>
                        <Select
                          labelId="size-select-label"
                          value={selectedSize}
                          label="Size"
                          onChange={handleSizeChange}
                        >
                          {productInfo.size.split(',').map(size => (
                            <MenuItem key={size.trim()} value={size.trim()}>
                              {size.trim()}
                              {['2XL', '3XL', '4XL', '5XL'].includes(size.trim()) &&
                                ` (+₹${size.trim() === '2XL' ? '150' :
                                  size.trim() === '5XL' ? '250' : '200'})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                     
                    </>
                  )}
                  {productInfo.sleeves && (
                     <FormControl fullWidth margin='normal'>
                     <InputLabel id="sleeve-select-label">Sleeve</InputLabel>
                     <Select
                       labelId="sleeve-select-label"
                       value={selectedSleeve}
                       label="Sleeve"
                       onChange={handleSleeveChange}
                     >
                       {sleeveOptions.map(option => (
                         <MenuItem key={option.type} value={option.type}>
                           {option.type}
                           {option.price > 0 && ` (+₹${option.price})`}
                         </MenuItem>
                       ))}
                     </Select>
                   </FormControl>
                  )}
                  <Box display='flex' sx={{ justifyContent: 'space-between' }}>
                    <Button
                      variant='contained'
                      sx={{ backgroundColor: '#d5a439', width: '40%' }}
                      onClick={handleAddToCart}
                    >
                      Add to Cart
                    </Button>
                    <Button
                      variant='contained'
                      sx={{ backgroundColor: '#d5a439', width: '40%' }}
                      onClick={handleBuyNow}
                    >
                      Buy Now
                    </Button>
                  </Box>
                </>
              ) : (
                <Alert
                  severity="error"
                  sx={{
                    mt: 2,
                    backgroundColor: '#fff3f3',
                    '& .MuiAlert-icon': {
                      color: '#d32f2f'
                    }
                  }}
                >
                  <Typography variant="h6" component="div" sx={{ color: '#d32f2f' }}>
                    Out of Stock
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#666' }}>
                    This item is currently unavailable. Please check back later or contact us for more information.
                  </Typography>
                </Alert>
              )}

              {errorMessage && (
                <Typography variant='body1' color='error'>
                  {errorMessage}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  )
}

export default memo(ProductInfo)

