import {
  Table,
  TableBody,
  Container,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  TextField,
  Box,
  Divider,
  Button,
  Card,
  CardContent
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useEffect, useState } from 'react'
import { useCart } from './CartContext'
import cards from '../Cards.png'
import fetchHeaderMessage from '../fetchHeaderMessage'
import addOrder from '../addOrder'
import StyledAlert from '../StyledAlert'

// Load Razorpay script dynamically
const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = 'https://checkout.razorpay.com/v1/checkout.js'
    script.async = true
    script.onload = () => resolve(true)
    document.body.appendChild(script)
  })
}

const PolicyCard = ({ title, content }) => {
  const processContent = (content) => {
    const lines = content.split('\n').map(line => line.trim());
    return lines.map((line, index) => {
      if (line.startsWith('•')) {
        const bulletContent = line.substring(1).trim();
        return (
          <li key={index} style={{ marginBottom: '8px', color: '#333' }}>
            {bulletContent}
          </li>
        );
      }
      return (
        <Typography key={index} variant="body2" sx={{ color: '#333', mb: 1 }}>
          {line}
        </Typography>
      );
    });
  };

  return (
    <Card sx={{ backgroundColor: '#fff5e1' }}>
      <CardContent>
        <Typography variant="h6" sx={{ color: '#d5a439', fontWeight: 'bold', mb: 2 }}>
          {title}
        </Typography>
        <ul style={{ paddingLeft: '20px', margin: 0 }}>
          {processContent(content)}
        </ul>
      </CardContent>
    </Card>
  );
};

const Checkout = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    type: 'success',
    message: '',
    title: ''
  });
  const initializeRazorpayPayment = async () => {
    if (!isFormValid()) {
      validateForm()
      return
    }

    const isScriptLoaded = await loadRazorpayScript()
    if (!isScriptLoaded) {
      alert('Failed to load payment gateway')
      return
    }
    // Create order details before payment
    const orderDetails = createOrderDetails()
    
    // Create Razorpay options
    const options = {
      key: 'rzp_live_nU8qUocdaSEXJ8', // Your Razorpay Key ID
      amount: payableAmount * 100, // Amount in paise
      currency: 'INR',
      name: 'Chirapathi',
      description: 'Order Payment',
      prefill: {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        contact: formData.phoneNumber1,
      },
      notes: {
        address: `${formData.addressLine1}, ${formData.city}, ${formData.state}, ${formData.postalCode}`,
      },
      handler: async function (response) {
        try {
          const transactionId = response.razorpay_payment_id;
          const formattedOrderDetails = orderDetails.join('\n');
          
          await addOrder(transactionId, formattedOrderDetails, cart);
          
          setAlertConfig({
            type: 'success',
            title: 'Order Placed Successfully!',
            message: 'Thank you for your purchase! For assistance or to track your order, please use the Payment ID provided in the email sent to your address. If you have any questions or need further help, feel free to contact us at +91 7358083536.'
          });
          setAlertOpen(true);
        } catch (error) {
          console.error('Error processing order:', error);
          setAlertConfig({
            type: 'error',
            title: 'Order Processing Failed',
            message: 'There was an error processing your order. Please contact support.'
          });
          setAlertOpen(true);
        }
      }
    ,
      modal: {
        ondismiss: function() {
          setAlertConfig({
            type: 'warning',
            title: 'Payment Error',
            message: 'Your payment is cancelled or failed'
          });
          setAlertOpen(true);
        }
      },
      theme: {
        color: '#d5a439'
      }
    }

    // Initialize Razorpay
    try {
      const razorpay = new window.Razorpay(options)
      razorpay.open()
    } catch (error) {
      console.error('Razorpay initialization error:', error)
      alert('Unable to initialize payment gateway. Please try again.')
    }
  }

  // Replace the existing handleCompletePayment with this
  const handleCompletePayment = () => {
    initializeRazorpayPayment()
  }

  const policies = [
    {
      title: 'Exchange',
      content: ` • Our products are customised according to each client preferences, so exchange won't be possible for size issues / colour variations.
          • Kindly ensure your size is correct before placing order.
          • Slight colour may vary due to monitor settings.`
    },
    {
      title: 'Return',
      content: ` • Return can be encouraged if proper proof of damage is provided, like complete video from opening of the parcel without any pause is required.
          To claim return, parcel need to be sent within 2 days also ensure it unused, post return product will be verified for further processing.`
    },
    {
      title: 'Refund',
      content: ` • No refund.
          Post order confirmation, order won't be possible for cancellation, hence no refund possible.`
    }
  ]

  const { cart, totalPrice, totalMRP } = useCart()
  const [discountCodes, setDiscountCodes] = useState()
  const [discountCode, setDiscountCode] = useState('')
  const [payableAmount, setPayableAmount] = useState(totalPrice + 100)
  const [discountAmount, setDiscountAmount] = useState(0)
  
  const [formData, setFormData] = useState({
    phoneNumber1: '',
    phoneNumber2: '',
    firstName: '',
    lastName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    postalCode: ''
  })

  const [errors, setErrors] = useState({})

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(email)
  }

  const validateForm = () => {
    const newErrors = {}
    
    // Validate required fields
    if (!formData.phoneNumber1.trim()) newErrors.phoneNumber1 = 'Required'
    if (!formData.firstName.trim()) newErrors.firstName = 'Required'
    if (!formData.lastName.trim()) newErrors.lastName = 'Required'
    if (!formData.email.trim()) {
      newErrors.email = 'Required'
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email format'
    }
    if (!formData.addressLine1.trim()) newErrors.addressLine1 = 'Required'
    if (!formData.state.trim()) newErrors.state = 'Required'
    if (!formData.city.trim()) newErrors.city = 'Required'
    if (!formData.postalCode.trim()) newErrors.postalCode = 'Required'

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const isFormValid = () => {
    return (
      formData.phoneNumber1.trim() &&
      formData.firstName.trim() &&
      formData.lastName.trim() &&
      formData.email.trim() &&
      validateEmail(formData.email) &&
      formData.addressLine1.trim() &&
      formData.state.trim() &&
      formData.city.trim() &&
      formData.postalCode.trim()
    )
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }))
    }
  }

  const getData = async () => {
    if (!discountCodes) {
      const couponCodes = await fetchHeaderMessage()
      const text = couponCodes[1].text
      const discountCodes = text.split(', ').reduce((acc, code) => {
        const [key, value] = code.split(':')
        acc[key] = parseInt(value, 10)
        return acc
      }, {})
      setDiscountCodes(discountCodes)
    }
  }

  useEffect(() => {
    getData()
  }, [discountCodes])

  const applyDiscount = () => {
    const discount = discountCodes[discountCode.toUpperCase()]
    if (discount) {
      const discountAmount = (totalPrice * discount) / 100
      setDiscountAmount(discountAmount)
      setPayableAmount(Math.ceil(parseFloat((totalPrice - discountAmount).toFixed(2))))
    } else {
      alert('Invalid discount code')
    }
  }

  const createOrderDetails = () => {
    const currentDate = new Date();
    const orderTime = currentDate.toLocaleString();

    const cartItems = cart.map(item => 
      `${item.name} - Size: ${item.selectedSize}, Sleeves: ${item.selectedSleeves}, Quantity: ${item.quantity}, Price: ₹${item.discount_price ? item.final_price : item.price}`
    ).join(' | ');

    const orderDetails = [
      `Order Time: ${orderTime}`,
      `Cart Items: ${cartItems}`,
      `Total MRP: ₹${totalMRP}`,
      `Total Price: ₹${totalPrice}`,
      `Shipping Charges: ₹100`,
      `Coupon Used: ${discountCode || 'None'}`,
      `Discount Amount: ₹${discountAmount}`,
      `Final Paid Amount: ₹${payableAmount}`,
      `Phone Number 1: ${formData.phoneNumber1}`,
      `Phone Number 2: ${formData.phoneNumber2}`,
      `Email: ${formData.email}`,
      `Customer Name: ${formData.firstName} ${formData.lastName}`,
      `Address Line 1: ${formData.addressLine1}`,
      `Address Line 2: ${formData.addressLine2}`,
      `City: ${formData.city}`,
      `State: ${formData.state}`,
      `Postal Code: ${formData.postalCode}`,
      `Country: India`
    ];

    return orderDetails;
  };
  return (
    <>
      <Container>
        <Typography variant="h6" sx={{ my: 2 }}>
          Order Summary
        </Typography>
        <TableContainer sx={{ marginBottom: 5 }} component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: 'lightgrey' }}>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Sleeves</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar src={item.main_image}  />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.selectedSize}</TableCell>
                  <TableCell>{item.selectedSleeves}</TableCell>
                  <TableCell>
                    {item.discount_price ? item.final_price : item.price}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography variant="h6">Total Price: ₹{totalPrice}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Divider />
      
      <Container maxWidth sx={{ padding: 3 }}>
        <Typography variant="h6">Contact Information</Typography>
        <Grid container my={2} spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Phone Number 1"
              name="phoneNumber1"
              value={formData.phoneNumber1}
              onChange={handleInputChange}
              variant="outlined"
              required
              error={!!errors.phoneNumber1}
              helperText={errors.phoneNumber1}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Phone Number 2 (Optional)"
              name="phoneNumber2"
              value={formData.phoneNumber2}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Container>

      <Divider />

      <Container maxWidth sx={{ padding: 3 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Shipping Address
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              variant="outlined"
              required
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              variant="outlined"
              required
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Email Address"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              variant="outlined"
              required
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
        </Grid>
        <Grid container mt={4} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Address Line 1"
              name="addressLine1"
              value={formData.addressLine1}
              onChange={handleInputChange}
              variant="outlined"
              required
              error={!!errors.addressLine1}
              helperText={errors.addressLine1}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Address Line 2"
              name="addressLine2"
              value={formData.addressLine2}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Select State"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              variant="outlined"
              required
              error={!!errors.state}
              helperText={errors.state}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Enter City"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              variant="outlined"
              required
              error={!!errors.city}
              helperText={errors.city}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              label="Postal Code"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleInputChange}
              variant="outlined"
              required
              error={!!errors.postalCode}
              helperText={errors.postalCode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ width: { xs: '40vh', md: '60vh' } }}
              disabled
              label="India"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          width: { xs: '100%', md: '40%' },
          border: '2px solid lightgrey',
          borderRadius: 4,
          padding: 3
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography fontWeight={700} variant="h6">
            Cart Total
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Typography>MRP</Typography>
          <Typography sx={{ textDecoration: 'line-through' }}>
            ₹{totalMRP}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Typography>Your Price</Typography>
          <Typography>₹{totalPrice}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Typography>Shipping Charges</Typography>
          <Typography>₹100</Typography>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Typography sx={{ fontWeight: 700 }}>Total</Typography>
          <Typography>₹{totalPrice + 100}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Typography sx={{ fontWeight: 700 }}>Coupon Discount</Typography>
          <Typography>-₹{discountAmount}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <TextField
            label="Discount Code"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
          />
          <Button variant="contained" onClick={applyDiscount}>
            Apply
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Typography sx={{ fontWeight: 700 }}>Payable Amount:</Typography>
          <Typography>₹{payableAmount}</Typography>
        </Box>
        <img src={cards} alt="Course" width="100%" style={{ marginTop: 3 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#d5a439', color: 'white' }}
            onClick={handleCompletePayment}
          >
            Complete Payment
          </Button>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ my: 3 }}>
        <Grid container spacing={2}>
          {policies.map((policy, index) => (
            <Grid item xs={12} md={4} key={index}>
              <PolicyCard title={policy.title} content={policy.content} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <StyledAlert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        type={alertConfig.type}
        title={alertConfig.title}
        message={alertConfig.message}
      />
    </>
  )
}

export default Checkout